<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" class="mt-16">
    <Breadcrumbs />

    <v-menu
      v-model="showColumnsMenu"
      :close-on-content-click="false"
      :nudge-width="450"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mr-5 inlineblock" v-bind="attrs" v-on="on" icon
          ><v-icon color="grey">mdi-cog-outline</v-icon></v-btn
        >
      </template>
      <v-card style="overflow:hidden;">
        <v-chip
          style="width:225px; margin:15px;"
          :color="allColumnsDisplayed ? 'primary' : 'grey'"
          @click="toggleAllColumns"
          text-color="white"
        >
          <v-avatar v-if="allColumnsDisplayed" left>
            <v-icon>mdi-check</v-icon>
          </v-avatar>
          {{ $t("filters.allfilters") }}
        </v-chip>
        <v-row no-gutters style="margin-right:50px;">
          <v-col cols="6">
            <v-list>
              <v-list-item v-for="(column, index) in columns.slice(0, columns.length / 2)" :key="index">
                <template>
                  <v-chip
                    style="width:225px;"
                    :color="column.active ? 'primary' : 'grey'"
                    @click="column.active = !column.active"
                    text-color="white"
                  >
                    <v-avatar v-if="column.active" left>
                      <v-icon>mdi-check</v-icon>
                    </v-avatar>
                    {{ column.title }}
                  </v-chip>
                </template>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col no-gutters cols="6">
            <v-list>
              <v-list-item v-for="(column, index) in columns.slice(columns.length / 2, columns.length)" :key="index">
                <template>
                  <v-chip
                    style="width:225px;"
                    :color="column.active ? 'primary' : 'grey'"
                    @click="column.active = !column.active"
                    text-color="white"
                  >
                    <v-avatar v-if="column.active" left>
                      <v-icon>mdi-check</v-icon>
                    </v-avatar>
                    {{ column.title }}
                  </v-chip>
                </template>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-btn icon absolute top right @click="showColumnsMenu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-menu>

    <v-text-field
        class="ml-3 mt-7"
        v-model="generalSearch"
        append-icon="mdi-magnify"
        :label="$t('general.search')"
        single-line
        hide-details
        dense
        filled
        clearable
        :loading="isSearching"
        @keydown="handleSearch"
        @click:clear="handleSearch"
        style="display:inline-block;min-width:400px;"
      ></v-text-field>

    <v-container :loading="!listMachineMotors" fluid>

      <div v-if="!listMachineMotors">
        <v-row>
          <v-col cols="12">
            <v-card class="border10">
              <v-skeleton-loader
                type="table-heading, divider, table-heading, divider, table-heading, divider"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div v-show="listMachineMotors">
        <v-row>
          <v-col cols="12">
            <v-card class="border10">
              <div ref="table"></div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>

  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator_bootstrap3.min.css";

import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { getLanguage, getLanguageWithoutRegion, parseError, getTabulatorLangsObject, objectMatchSearch, displayPaginationInfo } from "@/utils/utils";

import Breadcrumbs from "@/components/Breadcrumbs";
import moment from 'moment';
import UserService from "@/services/user.service";

var table = null;

function missingValueFormatter(cell) {
  let value = cell.getValue();

  if (value && value.length > 0) {
    return value;
  }
  return `<i style="color:rgb(206, 21, 21);" class="mdi mdi-flag"></i>`;
}

export default {
  name: "ListMachines",
  components: {
    Breadcrumbs,
  },
  data() {
      var scope = this;

      return {
        locale: this.$i18n.locale,
        listMachineMotors: null,
        showColumnsMenu: false,
        searchInput: "",
        today: moment(),
        tabulatorColumns: [],
        columns: [
          {
            field: "dealer",
            title: this.$t("listMachines.dealer"),
            active: true,
          },
          {
            field: "engineeringDepartment",
            title: this.$t("listMachines.engineeringDepartment"),
            formatter: missingValueFormatter,
            active: true,
          },
          {
            field: "isActive",
            title: this.$t("users.active"),
            formatter:"tickCross",
            hozAlign:"center",
            width: 100,
            active: true,
          },
          {
            field: "machine",
            title: this.$t("listMachines.machine"),
            formatter: missingValueFormatter,
            active: true,
          },
          {
            field: "serialNumber",
            title: this.$t("listMachines.serialNumber"),
            active: true,
          },
          {
            field: "motor", 
            title: this.$t("listMachines.motor"),
            formatter: missingValueFormatter,
            active: true,
          },
          {
            field: "isNotEmptyTorqueFormula",
            title: this.$t("listMachines.isEmptyTorqueFormula"),
            formatter:"tickCross",
            hozAlign:"center",
            width: 100,
            active: true,
          },
          {
            field: "isNotEmptyCompressionFormula",
            title: this.$t("listMachines.isEmptyCompressionFormula"),
            formatter:"tickCross",
            hozAlign:"center",
            width: 100,
            active: true,
          },
          {
            field: "isNotEmptyTensionFormula",
            title: this.$t("listMachines.isEmptyTensionFormula"),
            formatter:"tickCross",
            hozAlign:"center",
            width: 100,
            active: true,
          },
          {
            field: "isNotEmptyFactor",
            title: this.$t("listMachines.isEmptyFactor"),
            formatter:"tickCross",
            hozAlign:"center",
            width: 100,
            active: true,
          },
          {
            field: "correlationTable",
            title: this.$t("listMachines.correlationTable"),
            formatter:"tickCross",
            hozAlign:"center",
            width: 100,
            active: true,
          },
          {
            field: "lastCalibration",
            title: this.$t("listMachines.lastCalibration"),
            formatter: function missingValueFormatter(cell) {
              let value = cell.getValue();
              let item = cell.getData();

              if (value && value.length > 0) {
                let html = `<span>${value}</span>`;

                if (item.isCalibrationExpired) {
                  html += `<i title="` + scope.$t('listMachines.lastCalibrationExpired') + `" style="color:rgb(206, 21, 21);position:absolute;right:5px;" class="mdi mdi-flag"></i>`;  
                }
                return html;
              }
              return `<i style="color:rgb(206, 21, 21);" class="mdi mdi-flag"></i>`;
            },
            active: true,
          }
        ],
        generalSearch: '',
        isSearching: false
      };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    }),
    ...mapGetters({
      canManageSecurity: 'canManageSecurity',
      canManageParameters: 'canManageParameters'
    }),

    allColumnsDisplayed() {
      for (let column of this.columns) {
        if (!column.active) {
          return false;
        }
      }
      return true;
    },
  },
  watch: {
    showColumnsMenu() {
      if (!this.showColumnsMenu) {
        this.updateTabulatorColumns();
        table.setColumns(this.tabulatorColumns);

        this.refreshMachines();
      }
    },
  },
  methods: {
    ...mapMutations({
      openToast: "openToast",
      showErrorMessage: 'showErrorMessage'
    }),
    ...mapActions({
      setBreadcrumbs: "setBreadcrumbs",
      logout: 'logout'
    }),
    moment() {
      return moment();
    },
    updateTabulatorColumns() {
      this.tabulatorColumns = this.columns.filter(c => c.active);

      let displayedColumnKeys = this.tabulatorColumns.map(c => c.field);
      localStorage.setItem('displayedMachinesColumnKeys', displayedColumnKeys);
    },
    toggleAllColumns() {
      let allColumnsDisplayed = !this.allColumnsDisplayed;

      this.columns.forEach((column) => {
        column.active = allColumnsDisplayed;
      });
    },
    initTabulator() {

      var scope = this;

      let columnsLangData = {
        "dealer": this.$t("listMachines.dealer"),
        "engineeringDepartment": this.$t("listMachines.engineeringDepartment"),
        "isActive": this.$t("users.active"),
        "machine": this.$t("listMachines.machine"),
        "serialNumber": this.$t("listMachines.serialNumber"),
        "motor": this.$t("listMachines.motor"),
        "isNotEmptyTorqueFormula": this.$t("listMachines.isEmptyTorqueFormula"),
        "isNotEmptyCompressionFormula": this.$t("listMachines.isEmptyCompressionFormula"),
        "isNotEmptyTensionFormula": this.$t("listMachines.isEmptyTensionFormula"),
        "isNotEmptyFactor": this.$t("listMachines.isEmptyFactor"),
        "correlationTable": this.$t("listMachines.correlationTable"),
        "lastCalibration": this.$t("listMachines.lastCalibration"),
      };

      table = new Tabulator(this.$refs.table, {
        layout: "fitColumns",
        columns: this.tabulatorColumns,
        persistence: { sort: true, filter: true, columns: false },
        persistenceID: "persistenceMachinesReportV2",
        pagination: true,
        paginationSize: 10,
        paginationSizeSelector: [10, 25, 50, 100],
        paginationCounter: (pageSize, currentRow, currentPage, totalRows, totalPages) => displayPaginationInfo(pageSize, currentRow, currentPage, totalRows, totalPages, scope),
        locale: getLanguageWithoutRegion(getLanguage()),
        langs: getTabulatorLangsObject(this.$i18n, columnsLangData)
      });
      table.on("rowClick", function(e, row){
          scope.$router.push("/dealer/" + row.getData().dealerId);
      });
    },
    getListMachineMotors() {
      console.log("Reloading list of machine motors...");

      var scope = this;
      UserService.getListMachines(getLanguage())
        .then(function(response) {
          scope.listMachineMotors = response.data;
          console.log(scope.listMachineMotors)

          table.setData(scope.listMachineMotors);
        })
        .catch(function(error) {
          // handle error
          let errorMsg = parseError(error);
          //console.log(errorMsg);
          scope.showErrorMessage(errorMsg);
          if (error.toString().includes("401")) {
            scope.logout(this.$socket);
          } else if (
            error.toString().includes("500") &&
            scope.$router.currentRoute.path.includes("/engineeringDepartments/listmachines")
          ) {
            console.log("Redirecting to home from listMachines...");
            scope.$router.push("/");
          }
        })
    },
    handleSearch() {
      let scope = this;
      this.isSearching = true;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        scope.clearSearch();
      }, 1000);
    },
    clearSearch() {
      this.isSearching = false;
      
      this.refreshMachines();
    },
    refreshMachines() {
      let machines = this.listMachineMotors;
      if (this.generalSearch) {
        machines = machines.filter(u => objectMatchSearch(u, this.generalSearch, this.tabulatorColumns.map(c => c.field)));
      }
      table.setData(machines);
    }
  },
  mounted() {
    console.log("List of Machines mounted")
    this.setBreadcrumbs([
      { text: this.$t("general.engineering_department"), href: "/engineeringDepartments" },
      { text: this.$t("general.list_machines"), href: "/engineeringDepartments/listmachines" },
    ]);

    let displayedColumnKeys = localStorage.getItem('displayedMachinesColumnKeys');
    if (displayedColumnKeys) {
      displayedColumnKeys = displayedColumnKeys.split(',');
      for (let column of this.columns) {
        column.active = displayedColumnKeys.find(k => column.field == k) != undefined;
      }
    }
    this.updateTabulatorColumns();
    this.initTabulator();

    this.getListMachineMotors();
  },
};
</script>
<style scoped>
.badge-right-top {
  position: absolute!important;
  right: 20px;
  top: 20px;
}
.v-badge--tile .v-badge__badge {
  border-radius: 3px;
}
.v-breadcrumbs {
  display: inline-block;
}
</style> 